import React, { useEffect } from 'react';
import PaymentTable from '../components/payment/PaymentTable';
import PageWrapper from '../components/common/PageWrapper';
import PaymentSearchBar from '../components/payment/PaymentSearchBar';
import { usePayments } from '../context/PaymentContext';
const Payments: React.FC = () => {
  const {
    error,
    loading,
    payments,
    handleSearch,
    paginationModel,
    setSortModel,
    sortModel,
    setPaginationModel,
    setPaymentId,
  } = usePayments();

  useEffect(() => {
    setPaymentId(undefined);
  }, []);

  return (
    <PageWrapper error={error} loading={loading} title="Fingate payments">
      <PaymentSearchBar onSearch={handleSearch} />
      <PaymentTable
        rows={payments}
        loading={loading}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
        sortModel={sortModel}
        setSortModel={setSortModel}
      />
    </PageWrapper>
  );
};

export default Payments;

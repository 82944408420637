import React, { useCallback, useEffect, useState } from 'react';
import {
  TextField,
  Grid,
  Tooltip,
  IconButton,
  Paper,
  MenuItem,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  FetchCustomersParams,
  UserVerificationStatus,
} from '../../types/customerTypes';
import ClearIcon from '@mui/icons-material/Clear';
import slugToWords from '../../utils/slugToWords';
import { useSearchParams } from 'react-router-dom';

interface CustomerSearchBarProps {
  onSearch: (params: Partial<FetchCustomersParams>) => void;
}

const StyledPaper = React.memo(
  styled(Paper)({ padding: 16, marginBottom: 16 })
);
const StyledGrid = React.memo(
  styled(Grid)({ flexWrap: 'nowrap', overflowX: 'auto' })
);
const StyledTextField = React.memo(
  styled(TextField)({ minWidth: 200, maxWidth: 300 })
);

const CustomerSearchBar: React.FC<CustomerSearchBarProps> = ({ onSearch }) => {
  const [initialSearchParams] = useSearchParams();

  const [searchParams, setSearchParams] = useState<
    Partial<FetchCustomersParams>
  >({ ...Object.fromEntries(initialSearchParams.entries()) });

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      setSearchParams(searchParams => ({
        ...searchParams,
        [name]: value,
      }));
    },
    [setSearchParams]
  );

  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      onSearch(searchParams);
    }, 500);

    return () => clearTimeout(delayDebounce);
  }, [onSearch, searchParams]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onSearch(searchParams);
    }
  };

  const handleBlur = () => {
    onSearch(searchParams);
  };

  const handleClear = () => {
    setSearchParams({});
    onSearch({});
  };

  return (
    <StyledPaper>
      <StyledGrid container spacing={2} alignItems="center">
        <Grid item key="actorId">
          <TextField
            sx={{ minWidth: 200, maxWidth: 300 }}
            label="Actor Id"
            name="actorId"
            value={searchParams.actorId}
            onChange={handleInputChange}
            variant="outlined"
            size="small"
            onKeyDown={handleKeyDown}
            onBlur={handleBlur}
          />
        </Grid>
        <Grid item key="name">
          <TextField
            sx={{ minWidth: 200, maxWidth: 300 }}
            label={slugToWords('name')}
            name="name"
            value={searchParams.name}
            onChange={handleInputChange}
            variant="outlined"
            size="small"
            onKeyDown={handleKeyDown}
            onBlur={handleBlur}
          />
        </Grid>
        <Grid item key="email">
          <TextField
            sx={{ minWidth: 200, maxWidth: 300 }}
            label={slugToWords('email')}
            name="email"
            value={searchParams.email}
            onChange={handleInputChange}
            variant="outlined"
            size="small"
            onKeyDown={handleKeyDown}
            onBlur={handleBlur}
          />
        </Grid>
        <Grid item>
          <StyledTextField
            select
            label="Verification status"
            name="verificationStatus"
            value={searchParams.verificationStatus}
            onChange={handleInputChange}
            variant="outlined"
            size="small"
            onKeyDown={handleKeyDown}
            onBlur={handleBlur}
          >
            {Object.values(UserVerificationStatus).map(status => (
              <MenuItem key={status} value={status}>
                {slugToWords(status)}
              </MenuItem>
            ))}
          </StyledTextField>
        </Grid>
        <Grid item key="dateStart">
          <StyledTextField
            label={'Date start'}
            name="dateStart"
            type="datetime-local"
            value={searchParams.dateStart}
            onChange={handleInputChange}
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            size="small"
            onKeyDown={handleKeyDown}
            onBlur={handleBlur}
          />
        </Grid>
        <Grid item key="dateEnd">
          <StyledTextField
            label={'Date end'}
            name="dateEnd"
            type="datetime-local"
            value={searchParams.dateEnd}
            onChange={handleInputChange}
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            size="small"
            onKeyDown={handleKeyDown}
            onBlur={handleBlur}
          />
        </Grid>
        <Grid item>
          <Tooltip title="Clear">
            <IconButton onClick={handleClear}>
              <ClearIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </StyledGrid>
    </StyledPaper>
  );
};

export default CustomerSearchBar;

import React, { useCallback, useEffect, useState } from 'react';
import {
  TextField,
  Grid,
  Tooltip,
  IconButton,
  Paper,
  MenuItem,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  BOPaymentMethod,
  FetchPaymentsParams,
  PaymentStatus,
} from '../../types/paymentTypes';
import ClearIcon from '@mui/icons-material/Clear';
import slugToWords from '../../utils/slugToWords';
import { useSearchParams } from 'react-router-dom';

interface PaymentSearchBarProps {
  onSearch: (params: Partial<FetchPaymentsParams>) => void;
}

const StyledPaper = React.memo(
  styled(Paper)({ padding: 16, marginBottom: 16 })
);
const StyledGrid = React.memo(
  styled(Grid)({ flexWrap: 'nowrap', overflowX: 'auto' })
);
const StyledTextField = React.memo(
  styled(TextField)({ minWidth: 100, maxWidth: 300 })
);

const PaymentSearchBar: React.FC<PaymentSearchBarProps> = ({ onSearch }) => {
  const [initialSearchParams] = useSearchParams();

  const [searchParams, setSearchParams] = useState<
    Partial<FetchPaymentsParams>
  >({ ...Object.fromEntries(initialSearchParams.entries()) });

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      setSearchParams(searchParams => ({
        ...searchParams,
        [name]: value,
      }));
    },
    [setSearchParams]
  );

  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      onSearch(searchParams);
    }, 500);

    return () => clearTimeout(delayDebounce);
  }, [onSearch, searchParams]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onSearch(searchParams);
    }
  };

  const handleBlur = () => {
    onSearch(searchParams);
  };

  const handleClear = () => {
    setSearchParams({});
    onSearch({});
  };

  return (
    <StyledPaper>
      <StyledGrid container spacing={1} alignItems="center">
        <Grid item key="walletId">
          <StyledTextField
            label="Wallet id"
            name="walletId"
            value={searchParams.walletId || ''}
            onChange={handleInputChange}
            variant="outlined"
            size="small"
            onKeyDown={handleKeyDown}
            onBlur={handleBlur}
          />
        </Grid>
        <Grid item key="paymentId">
          <StyledTextField
            label="Payment Id"
            name="paymentId"
            value={searchParams.paymentId}
            onChange={handleInputChange}
            variant="outlined"
            size="small"
            onKeyDown={handleKeyDown}
            onBlur={handleBlur}
          />
        </Grid>
        <Grid item key="fingateId">
          <StyledTextField
            label="Fingate Id"
            name="fingateId"
            value={searchParams.fingateId}
            onChange={handleInputChange}
            variant="outlined"
            size="small"
            onKeyDown={handleKeyDown}
            onBlur={handleBlur}
          />
        </Grid>
        <Grid item key="currencyCode">
          <StyledTextField
            label="Currency code"
            name="currencyCode"
            value={searchParams.currencyCode}
            onChange={handleInputChange}
            variant="outlined"
            size="small"
            onKeyDown={handleKeyDown}
            onBlur={handleBlur}
          />
        </Grid>
        <Grid item>
          <StyledTextField
            select
            label="Status"
            name="status"
            value={searchParams.status || ''}
            onChange={handleInputChange}
            variant="outlined"
            size="small"
            onKeyDown={handleKeyDown}
            onBlur={handleBlur}
          >
            {Object.values(PaymentStatus).map(status => (
              <MenuItem key={status} value={status}>
                {slugToWords(status)}
              </MenuItem>
            ))}
          </StyledTextField>
        </Grid>
        <Grid item>
          <StyledTextField
            select
            label="Payment method"
            name="method"
            value={searchParams.method || ''}
            onChange={handleInputChange}
            variant="outlined"
            size="small"
            onKeyDown={handleKeyDown}
            onBlur={handleBlur}
          >
            {Object.values(BOPaymentMethod).map(method => (
              <MenuItem key={method} value={method}>
                {slugToWords(method)}
              </MenuItem>
            ))}
          </StyledTextField>
        </Grid>
        <Grid item key="dateStart">
          <StyledTextField
            label={'Date start'}
            name="dateStart"
            type="datetime-local"
            value={searchParams.dateStart}
            onChange={handleInputChange}
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            size="small"
            onKeyDown={handleKeyDown}
            onBlur={handleBlur}
          />
        </Grid>
        <Grid item key="dateEnd">
          <StyledTextField
            label={'Date end'}
            name="dateEnd"
            type="datetime-local"
            value={searchParams.dateEnd}
            onChange={handleInputChange}
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            size="small"
            onKeyDown={handleKeyDown}
            onBlur={handleBlur}
          />
        </Grid>
        <Grid item>
          <Tooltip title="Clear">
            <IconButton onClick={handleClear}>
              <ClearIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </StyledGrid>
    </StyledPaper>
  );
};

export default PaymentSearchBar;

import Keycloak, { KeycloakConfig } from 'keycloak-js';

const keycloakConfig: KeycloakConfig = {
  url: 'https://keycloak-one.spikeapp.cool/',
  realm: 'stormpay',
  clientId: 'front-admin',
};

const keycloak = new Keycloak(keycloakConfig);
export default keycloak;

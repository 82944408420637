import React from 'react';
import {
  GridColDef,
  GridPaginationModel,
  GridSortModel,
  GridRenderCellParams,
} from '@mui/x-data-grid';
import { PaymentShortInfo, PaymentStatus } from '../../types/paymentTypes';
import { PaginatedResponse } from '../../types/common';
import { formatDateTime } from '../../utils/dateFormatter';
import Table from '../common/Table';
import StatusChip from './StatusChip';
import slugToWords from '../../utils/slugToWords';
import { formatMoney } from '../../utils/formatMoney';

interface PaymentTableProps {
  rows: PaginatedResponse<PaymentShortInfo>;
  paginationModel: GridPaginationModel;
  setPaginationModel: (model: GridPaginationModel) => void;
  sortModel: GridSortModel;
  setSortModel: (model: GridSortModel) => void;
  loading: boolean;
}

const columns: GridColDef[] = [
  { field: 'direction', headerName: 'Direction', flex: 1, sortable: false },
  {
    field: 'amount',
    headerName: 'Amount',
    flex: 1,
    sortable: false,
    renderCell: (params: GridRenderCellParams) =>
      formatMoney(params.row.amount, params.row.currencyCode),
  },
  {
    field: 'feeAmount',
    headerName: 'Fee amount',
    flex: 1,
    sortable: false,
    renderCell: (params: GridRenderCellParams) =>
      formatMoney(params.row.feeAmount, params.row.currencyCode),
  },
  {
    field: 'method',
    headerName: 'Payment method',
    flex: 1,
    sortable: false,
    renderCell: (params: GridRenderCellParams) =>
      slugToWords(params.row.method),
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 1,
    sortable: false,
    renderCell: (params: GridRenderCellParams) => (
      <StatusChip status={params.value as PaymentStatus} />
    ),
  },
  {
    field: 'createdAt',
    headerName: 'Created',
    flex: 1,
    sortable: false, //ToDo: change to true when SPK-1013 fixed
    renderCell: (params: GridRenderCellParams) =>
      formatDateTime(params.row.createdAt),
  },
  { field: 'paymentId', headerName: 'Payment Id', flex: 1, sortable: false }, //ToDo: change to true when SPK-1013 fixed
  { field: 'fingateId', headerName: 'Fingate Id', flex: 1, sortable: false },
  { field: 'walletId', headerName: 'Wallet Id', flex: 1, sortable: false },
];

const PaymentTable: React.FC<PaymentTableProps> = ({
  loading,
  paginationModel,
  rows,
  setPaginationModel,
  setSortModel,
  sortModel,
}) => (
  <Table
    rows={rows.items}
    columns={columns}
    paginationModel={paginationModel}
    setPaginationModel={setPaginationModel}
    loading={loading}
    rowCount={rows?.pagination?.total || 0}
    getRowId={item => item.fingateId}
    sortModel={sortModel}
    setSortModel={setSortModel}
  />
);

export default PaymentTable;

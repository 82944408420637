import React, {
  createContext,
  useContext,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { fetchCustomerById, fetchCustomers } from '../services/customerService';
import { PaginatedResponse } from '../types/common';
import {
  Customer,
  CustomerDetails,
  FetchCustomersParams,
} from '../types/customerTypes';
import { GridPaginationModel, GridSortModel } from '@mui/x-data-grid';
import {
  URLSearchParamsInit,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import useLocalStorage from '../hooks/useLocalStorage';
import useAuthenticatedApi from '../services/useAuthenticatedApi';

interface CustomerContextProps {
  currentCustomer?: CustomerDetails;
  customerId?: string;
  customers: PaginatedResponse<Customer>;
  error: string | null;
  handleEdit: (id: string) => void;
  handleSearch: (params: Partial<FetchCustomersParams>) => void;
  handleView: (id: string) => void;
  loading: boolean;
  paginationModel: GridPaginationModel;
  setCustomerId: (id?: string) => void;
  setPaginationModel: React.Dispatch<React.SetStateAction<GridPaginationModel>>;
  setSortModel: React.Dispatch<React.SetStateAction<GridSortModel>>;
  sortModel: GridSortModel;
  update: () => void;
}

interface CustomerProviderProps {
  children: React.ReactNode;
}

const CustomerContext = createContext<CustomerContextProps | undefined>(
  undefined
);

export const CustomerProvider: React.FC<CustomerProviderProps> = ({
  children,
}) => {
  const navigate = useNavigate();
  const [customerId, setCustomerId] = useState<string>();
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 10,
  });
  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: 'name', sort: 'asc' },
  ]);
  const [customers, setCustomers] = useState<PaginatedResponse<Customer>>({
    items: [],
    pagination: { total: 0, limit: 10, offset: 0 },
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentCustomer, setCurrentCustomer] = useState<CustomerDetails>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  // const { addNotification } = useNotifications();
  const [customerTableSettings, setCustomerTableSettings] = useLocalStorage(
    'customerTableSettings',
    { paginationModel, sortModel }
  );
  const { callApiWithAuth } = useAuthenticatedApi();

  useEffect(() => {
    if (customerTableSettings) {
      setPaginationModel(customerTableSettings.paginationModel);
      setSortModel(customerTableSettings.sortModel);
    } else {
      setCustomerTableSettings({ paginationModel, sortModel });
    }
  }, []);

  useEffect(() => {
    setCustomerTableSettings({ paginationModel, sortModel });
  }, [paginationModel, sortModel]);

  const getCustomers = useCallback(async () => {
    setLoading(true);
    try {
      const data = await callApiWithAuth(fetchCustomers, {
        ...Object.fromEntries(searchParams.entries()),
        limit: paginationModel.pageSize,
        offset: paginationModel.page * paginationModel.pageSize,
        sort: sortModel[0]?.field,
        sortDir: sortModel[0]?.sort || undefined,
      });
      setCustomers(prevState => ({
        items: data.items,
        pagination: {
          total: data.total_items,
          limit: prevState.pagination.limit,
          offset: prevState.pagination.offset,
        },
      }));
    } catch (error) {
      setError((error as Error).message);
    } finally {
      setLoading(false);
    }
  }, [searchParams, paginationModel, sortModel]);

  useEffect(() => {
    setError(null);
    getCustomers();
  }, [paginationModel, sortModel, searchParams, customerId]);

  useEffect(() => {
    if (customerId) {
      getCustomerById(customerId);
    }
  }, [customerId]);

  const handleSearch = (params: Partial<FetchCustomersParams>) => {
    const nonEmptyParams = Object.fromEntries(
      Object.entries(params).filter(([, value]) => value)
    ) as URLSearchParamsInit;
    setSearchParams(nonEmptyParams);
  };

  const handleEdit = (id: string) => navigate(`/customers/${id}/edit`);

  const handleView = (id: string) => navigate(`/customers/${id}`);

  const getCustomerById = useCallback(
    async (customerId: string) => {
      setLoading(true);
      setCurrentCustomer(undefined);
      try {
        const customer = await callApiWithAuth(fetchCustomerById, customerId);
        setCurrentCustomer(customer);
      } catch (error) {
        setError((error as Error).message);
      } finally {
        setLoading(false);
      }
    },
    [customerId]
  );

  const update = async () => {
    setLoading(true);
    try {
      if (customerId) getCustomerById(customerId);
      const data = await callApiWithAuth(fetchCustomerById, {
        ...searchParams,
        limit: paginationModel.pageSize,
        offset: paginationModel.page * paginationModel.pageSize,
        sort: sortModel[0]?.field,
        sortDir: sortModel[0]?.sort || undefined,
      });
      setCustomers(data);
      setPaginationModel(() => ({
        page: data.page,
        pageSize: data.pageSize,
      }));
    } catch (error) {
      setError((error as Error).message);
    } finally {
      setLoading(false);
    }
  };
  return (
    <CustomerContext.Provider
      value={{
        customers,
        currentCustomer,
        error,
        handleSearch,
        loading,
        paginationModel,
        setPaginationModel,
        setSortModel,
        sortModel,
        handleEdit,
        handleView,
        customerId,
        setCustomerId,
        update,
      }}
    >
      {children}
    </CustomerContext.Provider>
  );
};

export const useCustomers = (): CustomerContextProps => {
  const context = useContext(CustomerContext);
  if (!context) {
    throw new Error('useCustomer must be used within a CustomerProvider');
  }
  return context;
};

import React from 'react';
import {
  GridColDef,
  GridPaginationModel,
  GridSortModel,
  GridRenderCellParams,
} from '@mui/x-data-grid';
import {
  Action,
  Customer,
  UserVerificationStatus,
} from '../../types/customerTypes';
import { PaginatedResponse } from '../../types/common';
import { formatDateTime } from '../../utils/dateFormatter';
import Table from '../common/Table';
import ActionButtons from './CustomerActionButtons';
import StatusChip from './StatusChip';
import slugToWords from '../../utils/slugToWords';

interface CustomerTableProps {
  rows: PaginatedResponse<Customer>;
  paginationModel: GridPaginationModel;
  setPaginationModel: (model: GridPaginationModel) => void;
  sortModel: GridSortModel;
  setSortModel: (model: GridSortModel) => void;
  loading: boolean;
}

const columns: GridColDef[] = [
  { field: 'actorId', headerName: 'Actor Id', flex: 1, sortable: false },
  { field: 'name', headerName: 'Name', flex: 1, sortable: true },
  {
    field: 'email',
    headerName: 'Email',
    flex: 1,
    sortable: false,
    renderCell: (params: GridRenderCellParams) => (
      <a href={`mailto:${params.value}`} type="email">
        {params.value}
      </a>
    ),
  },
  {
    field: 'createTime',
    headerName: 'Created',
    flex: 1,
    renderCell: (params: GridRenderCellParams) =>
      formatDateTime(params.row.createTime),
  },
  {
    field: 'updateTime',
    headerName: 'Updated',
    flex: 1,
    sortable: false,
    renderCell: (params: GridRenderCellParams) =>
      formatDateTime(params.row.updateTime),
  },
  {
    field: 'verificationStatus',
    headerName: slugToWords('verificationStatus'),
    flex: 1,
    sortable: false,
    renderCell: (params: GridRenderCellParams) => (
      <StatusChip status={params.value as UserVerificationStatus} />
    ),
  },
  {
    field: 'actions',
    headerName: 'Actions',
    flex: 1.5,
    sortable: false,
    renderCell: (params: GridRenderCellParams) => (
      <ActionButtons actions={[Action.VIEW]} id={params.row.actorId} />
    ),
  },
];

const CustomerTable: React.FC<CustomerTableProps> = ({
  loading,
  paginationModel,
  rows,
  setPaginationModel,
  setSortModel,
  sortModel,
}) => (
  <Table
    rows={rows.items}
    columns={columns}
    paginationModel={paginationModel}
    setPaginationModel={setPaginationModel}
    loading={loading}
    rowCount={rows?.pagination?.total || 0}
    getRowId={item => item.actorId}
    sortModel={sortModel}
    setSortModel={setSortModel}
  />
);

export default CustomerTable;
